@charset 'UTF-8';
.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}
.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: slick;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-loading .slick-list {
}
.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 16px;
  height: 16px;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: 0;
  background: 0 0;
  z-index: 1;
  border: solid rgba(255, 255, 255, 0.5);
  border-width: 0 3px 3px 0;
  padding: 3px;
  transition: 0.2s ease all;
}
.slick-next:hover,
.slick-prev:hover {
  border-color: rgba(255, 255, 255, 1);
}
.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0 0;
}
.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}
.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #fff;
}
.slick-prev {
  left: 25px;
  transform: rotate(135deg);
}
[dir="rtl"] .slick-prev {
  right: 25px;
  left: auto;
}
.slick-prev:before {
  content: "";
}
.slick-next:before,
[dir="rtl"] .slick-prev:before {
  content: "";
}
.slick-next {
  right: 25px;
  transform: rotate(-45deg);
}
[dir="rtl"] .slick-next {
  right: auto;
  left: 25px;
}
[dir="rtl"] .slick-next:before {
  content: "";
}
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.slick-dots {
  bottom: 25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0 0;
}
.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}
.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}
.slick-dots li button:before {
  font-size: 6px;
  line-height: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 12px;
  height: 12px;
  content: "";
  text-align: center;
  opacity: 0.25;
  color: #000;
  background: #fff;
  border-radius: 50%;
}
.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
} /*# sourceMappingURL=slick-theme.min.css.map */

/* custom */

.slick-slide div:focus {
  outline: none;
}

/* Override track calculations */
.depth-mode .slick-track {
  transform: none !important;
  width: 100% !important;
}

.depth-mode .slick-slide {
  float: right;
  transition: 0.5s ease all;
  position: absolute;
  right: 0;
  margin-left: auto;
  margin-right: 0;
  overflow: hidden;
}

/* All inactive slides */
.depth-mode .slick-slide {
  opacity: 0.1;
  z-index: 1;
  width: 0;
}

/* The active left hand slide */
.depth-mode .slick-active {
  opacity: 1;
  margin-right: 40px;
  z-index: 3;
  width: calc(100% - 40px) !important;
  min-width: 50%;
  min-width: 300px;
  margin-top: 80px;
  height: calc(100% - 160px);
  filter: brightness(0.6);
}

/* The active middle slide */
.depth-mode .slick-current {
  opacity: 1;
  margin-right: 20px;
  z-index: 4;
  width: calc(100% - 60px) !important;
  min-width: 50%;
  min-width: 300px;
  margin-top: 40px;
  height: calc(100% - 80px);
  filter: brightness(0.8);
}

/* The active right hand slide */
.depth-mode .slick-current + .slick-active {
  position: relative;
  opacity: 1;
  margin-right: 0;
  z-index: 5;
  width: calc(100% - 80px) !important;
  min-width: 50%;
  min-width: 300px;
  margin-top: 0;
  height: 100%;
  filter: brightness(1);
}

.depth-mode button.slick-prev,
.depth-mode button.slick-next {
  top: 20px;
  right: 20px;
  bottom: unset;
  left: unset;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: transparent;
  transform: none;
  width: 35px;
  height: 35px;
  transform: rotateZ(-90deg);
  opacity: 0.6;
  transition: 0.4s ease all;
}

.depth-mode button.slick-next {
  top: 54px;
  transform: rotateZ(90deg);
}

.depth-mode button.slick-prev:hover,
.depth-mode button.slick-next:hover {
  border: 1px solid #000000;
  opacity: 1;
}

.depth-mode button.slick-prev:after,
.depth-mode button.slick-next:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 6px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 50 50' version='1.1'%3E%3Cg id='surface1'%3E%3Cpath style=' ' d='M 25 1.015625 L 14.308594 11.28125 C 14.046875 11.527344 13.9375 11.894531 14.023438 12.242188 C 14.109375 12.589844 14.375 12.867188 14.71875 12.964844 C 15.066406 13.066406 15.4375 12.972656 15.691406 12.71875 L 24 4.746094 L 24 48 C 23.996094 48.359375 24.183594 48.695313 24.496094 48.878906 C 24.808594 49.058594 25.191406 49.058594 25.503906 48.878906 C 25.816406 48.695313 26.003906 48.359375 26 48 L 26 4.746094 L 34.308594 12.71875 C 34.5625 12.972656 34.933594 13.066406 35.28125 12.964844 C 35.625 12.867188 35.890625 12.589844 35.976563 12.242188 C 36.0625 11.894531 35.953125 11.527344 35.691406 11.28125 Z '%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: initial;
}
