html {
  box-sizing: border-box;
  // background: #fffbf5;
  transition: 0.4s ease all;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: Calibre, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* no-scroll is applied when BookingSidebar is active in order to prevent scrolling of the content below */
body.no-scroll {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
}

body.no-scroll #page-wrap {
  transition: 0.5s ease all;
  opacity: 0.1;
}

section {
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.boldest {
  font-weight: 900;
}

@mixin max-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.max-lines-1 {
  @include max-lines;
  -webkit-line-clamp: 1;
}

.max-lines-2 {
  @include max-lines;
  -webkit-line-clamp: 2;
}

.max-lines-3 {
  @include max-lines;
  -webkit-line-clamp: 3;
}

@font-face {
  font-family: 'Acrom';
  src: url(./assets/fonts/Acrom-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Acrom';
  src: url(./assets/fonts/Acrom-Bold.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Acrom';
  src: url(./assets/fonts/Acrom-ExtraBold.ttf);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url(./assets/fonts/Calibre-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url(./assets/fonts/Calibre-Light.ttf);
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url(./assets/fonts/Calibre-Medium.otf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url(./assets/fonts/Calibre-Semibold.otf);
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Calibre';
  src: url(./assets/fonts/Calibre-Bold.otf);
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Cheltenham';
  src: url(./assets/fonts/Cheltenham-Regular.ttf);
  font-weight: normal;
  font-style: normal;
}

.Toastify__toast-container--top-center .Toastify__toast-body {
  text-align: center;
}
